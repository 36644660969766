import Axios from 'axios';
import { IContent } from 'src/interfaces/Content';
import { IUser } from 'src/interfaces/User';
import { IMedia } from 'src/interfaces/Media';
import { IServiceAction } from 'src/interfaces/Service';
import { Granularity } from 'src/interfaces/Granularity';

export const axios = Axios.create({
    baseURL: process.env.REACT_APP_STRAPI_URL
});

const headers = () => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
});

export const postLogin = async (identifier: string, password: string) => {
    const response = await axios.post(
        '/auth/local',
        { identifier, password },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
    if (response?.data?.user?.role?.name !== 'companyAdmin') {
        throw new Error();
    }
    return response;
};

export const getProfile = async () => {
    const me = await axios.get('/users/me', { headers: headers() });
    if (!me.data || !me.data.role || me.data.role.name !== 'companyAdmin') {
        throw new Error();
    }
    return me;
};

export const getCompany = async () => {
    return await axios.get('/companies/owned', { headers: headers() });
};

export const getAllContents = async (): Promise<IContent[]> => {
    const response = await axios.get('/contents/admin', { headers: headers() });
    const all = response.data.map((x: any) => ({
        ...x,
        state: x.published_at ? 'published' : 'draft'
    }));
    return all;
};

export const getContentById = async (id: string) => {
    return await axios.get(`/contents/admin/${id}`, { headers: headers() });
};

export const getAllUsers = async (): Promise<IUser[]> => {
    const response = await axios.get('/employees', { headers: headers() });
    const all = response.data.map((x: any) => ({
        ...x,
        state: x.published_at ? 'published' : 'draft'
    }));
    return all;
};

export const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('files', file);
    const { 'Content-Type': _, ...header } = headers();
    return await axios.post('/upload', formData, { headers: header });
};

export interface IUpdateCompany {
    name: string;
    color1: string;
    color2: string;
    logo: IMedia;
    logo_small: IMedia;
    code: string;
    pinned_content_1: string | null;
    pinned_content_2: string | null;
    pinned_content_3: string | null;
    pinned_content_4: string | null;
}

export const updateCompany = async (id: string, data: Partial<IUpdateCompany>) => {
    return await axios.put(`/companies/${id}`, data, { headers: headers() });
};

export const getEmployeeById = async (id: string) => {
    return await axios.get(`/employees/${id}`, { headers: headers() });
};

export interface IUpdateEmployee {
    email: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    joinCode: string;
    language: string;
    country: string;
}
export const updateEmployee = async (id: string, data: Partial<IUpdateEmployee>) => {
    return await axios.put(`/employees/${id}`, data, { headers: headers() });
};
export const getCode = async (id: string) => {
    return await axios.get(`/companies/${id}/code`, { headers: headers() });
};

export interface IPostContent {
    title: string;
    description: string;
    mainImage: IMedia;
    duration?: number;
    body?: string;
    audios?: IMedia[];
    videos?: IMedia[];
    link?: string;
}

export const postContent = async (type: string, data: IPostContent) => {
    return await axios.post(`/contents`, { ...data, type }, { headers: headers() });
};

export const updateContent = async (id: string, data: IPostContent) => {
    return await axios.put(`/contents/${id}`, data, { headers: headers() });
};

export const deleteContent = async (id: string) => {
    return await axios.delete(`/contents/${id}`, { headers: headers() });
};

export const publishContentRequest = async (id: string) => {
    return await axios.put(
        `/contents/${id}/publish`,
        {},
        {
            headers: headers()
        }
    );
};

export const unpublishContentRequest = async (id: string) => {
    return await axios.put(
        `/contents/${id}/unpublish`,
        {},
        {
            headers: headers()
        }
    );
};

export const getTags = async () => {
    return await axios.get('/tags', { headers: headers() });
};

export const getServices = async () => {
    return await axios.get('/services/admin', { headers: headers() });
};

export interface IPostService {
    title: string;
    description: string;
    image: IMedia;
    body?: string;
    ServiceAction: IServiceAction[];
}

export const postService = async (data: IPostService) => {
    return await axios.post('/services', data, { headers: headers() });
};

export const getServiceById = async (id: string) => {
    return await axios.get(`/services/admin/${id}`, { headers: headers() });
};

export const deleteService = async (id: string) => {
    return await axios.delete(`/services/${id}`, { headers: headers() });
};

export const updateService = async (id: string, data: IPostService) => {
    return await axios.put(`/services/${id}`, data, { headers: headers() });
};

// statistics

export const getUserCount = async () => {
    return await axios.get(`/statistics/userCount`, {
        headers: headers()
    });
};

export const getTotalContents = async (gran?: Granularity) => {
    return await axios.get(`/statistics/totalContents?gran=${gran}`, { headers: headers() });
};

export const getTotalServices = async (gran?: Granularity) => {
    return await axios.get(`/statistics/totalServices?gran=${gran}`, { headers: headers() });
};

export const getContentViews = async (gran?: Granularity, startDate?: string, endDate?: string) => {
    return await axios.get(
        `/statistics/contentViews?gran=${gran}&begin=${startDate}&end=${endDate}`,
        {
            headers: headers()
        }
    );
};

export const getServiceViews = async (gran?: Granularity, startDate?: string, endDate?: string) => {
    return await axios.get(
        `/statistics/serviceViews?gran=${gran}&begin=${startDate}&end=${endDate}`,
        {
            headers: headers()
        }
    );
};

export const getPopularTags = async () => {
    return await axios.get('/statistics/popularTags', { headers: headers() });
};

export const getVisits = async (
    gran?: Granularity,
    startDate?: string,
    endDate?: string,
    unique?: boolean
) => {
    return await axios.get(
        `/statistics/visits?gran=${gran}&unique=${unique}&begin=${startDate}&end=${endDate}`,
        {
            headers: headers()
        }
    );
};

export const getPopularContents = async () => {
    return await axios.get('/statistics/popularContent', { headers: headers() });
};

export const getPopularServices = async () => {
    return await axios.get('/statistics/popularServices', { headers: headers() });
};

export interface PushNotification {
    title: string;
    body: string;
}

export const sendPushNotificationRequest = async (data: PushNotification) => {
    return await axios.post('/notification/push', data, { headers: headers() });
};

export const getMonthTotalViews = async () => {
    return await axios.get('/statistics/viewsThisMonth', { headers: headers() });
};

export const getMonthTotalVisits = async () => {
    return await axios.get('/statistics/visitsThisMonth', { headers: headers() });
};
