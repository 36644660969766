import React, { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { Loading } from './components/Loading';
import './scss/style.scss';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
    const { isAuth } = useAuth();

    if (isAuth === null) {
        return <Loading />;
    }

    return (
        <Router>
            <Suspense fallback={Loading}>
                {isAuth ? (
                    <Switch>
                        <Route exact path="/404" render={props => <Page404 {...props} />} />
                        <Route exact path="/500" render={props => <Page500 {...props} />} />
                        <Route path="/" render={props => <TheLayout {...props} />} />
                    </Switch>
                ) : (
                    <Switch>
                        <Route exact path="/login" render={props => <Login {...props} />} />
                        <Route path="*" exact render={() => <Redirect to="/login" />} />
                    </Switch>
                )}
            </Suspense>
        </Router>
    );
};

export default App;
