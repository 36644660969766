import {
    cilBasket,
    cilBell,
    cilChartPie,
    cilCommentSquare,
    cilCreditCard,
    cilEnvelopeOpen,
    cilFile,
    cilGraph,
    cilList,
    cilLockLocked,
    cilMagnifyingGlass,
    cilSettings,
    cilSpeech,
    cilSpeedometer,
    cilTask,
    cilUser,
    cilUserFollow,
    cilUserUnfollow,
    cilPenNib,
    cilReload,
    cilLockUnlocked,
    cilBrush,
    cilAccountLogout,
    cilArrowLeft,
    cilPlus,
    cilTrash,
    cilCog,
    cilArrowTop,
    cilArrowBottom,
    cilChevronCircleRightAlt,
    cilSend,
    cibPinboard,
    cilHome,
    cilTouchApp
} from '@coreui/icons';

export const icons = Object.assign(
    {},
    {
        cilBasket,
        cilBell,
        cilChartPie,
        cilCommentSquare,
        cilCreditCard,
        cilEnvelopeOpen,
        cilFile,
        cilGraph,
        cilList,
        cilLockLocked,
        cilMagnifyingGlass,
        cilSettings,
        cilSpeech,
        cilSpeedometer,
        cilTask,
        cilUser,
        cilUserFollow,
        cilUserUnfollow,
        cilPenNib,
        cilReload,
        cilLockUnlocked,
        cilBrush,
        cilAccountLogout,
        cilArrowLeft,
        cilPlus,
        cilTrash,
        cilCog,
        cilArrowTop,
        cilArrowBottom,
        cilChevronCircleRightAlt,
        cilSend,
        cibPinboard,
        cilHome,
        cilTouchApp
    }
);
