import { IUser } from '../interfaces/User';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getProfile, postLogin } from '../api';

type authContextType = {
    isAuth: boolean | null;
    login: (identifier: string, password: string) => Promise<void>;
    logout: () => void;
    user: IUser | null;
};

const authContextDefaultValues: authContextType = {
    isAuth: null,
    login: async () => {},
    logout: () => {},
    user: null
};

const AuthContext = createContext<authContextType>(authContextDefaultValues);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

type Props = {
    children: ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
    const [isAuth, setisAuth] = useState<boolean | null>(null);
    const [user, setUser] = useState<IUser | null>(null);

    useEffect(() => {
        getProfile()
            .then(res => {
                setisAuth(true);
                setUser(res.data);
            })
            .catch(() => logout());
    }, []);

    const login = async (identifier: string, password: string) => {
        const response = await postLogin(identifier, password);
        localStorage.setItem('token', response.data.jwt);
        setisAuth(true);
        const profile = await getProfile();
        setUser(profile.data);
    };

    const logout = () => {
        localStorage.removeItem('token');
        setisAuth(false);
    };

    const value = {
        isAuth,
        login,
        logout,
        user
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
